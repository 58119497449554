/*! common.js
 * Copyright Projekt-Trolley
 */
var mobjTimeoutInterval;
var mobjInitPoInterval;
var mblLoggedInPage;
$(document).ready(function(){
    if ($.views)
        $.views.settings.delimiters("[%", "%]");
	initPage()
});

function deleteCookie(strName, strPath, strDomain) {
    if (getCookie(strName))
        document.cookie = escape(strName) + "=" + ((strPath) ? ";path=" + strPath : "") + ((strDomain) ? ";domain=" + strDomain : "") + ";expires=Thu, 01-Jan-1970 00:00:01 GMT";
}

function getCookie(strName) {
    var strCookie = unescape(document.cookie.replace(/\+/g, " "));
    var intStart = strCookie.indexOf(strName + "=");
    var intLen = intStart + strName.length + 1;
    if (!intStart && strName != strCookie.substring(0, strName.length))
        return "";
    if (intStart == -1)
        return "";
    var intEnd = strCookie.indexOf("; ", intLen);
    if (intEnd == -1)
        intEnd = strCookie.length;
    return utf8Decode(strCookie.substring(intLen, intEnd));
}

function setCookie(strName, strValue, dtmExpires, strPath, strDomain, strSecure) {
    document.cookie = escape(strName) + "=" + escape(strValue) + ((dtmExpires) ? ";expires=" + dtmExpires.toUTCString() : "") + ((strPath) ? ";path=" + strPath : ";path=/") + ((strDomain) ? ";domain=" + strDomain : "") + ((strSecure) ? ";secure" : "");
}

function bindPageEventHandlers()
{
	$("body").on('click',"a",function(){
		switch(this.target){
			case'help':
				openPopup(this.href,0,0,'yes','yes','yes','yes','help');
				return false;
			case'objScreenshot':
				openPopup(this.href,1000,1000,'no','no','no','no','objScreenshot');
				return false;
			case'objPopup':
				openPopup(this.href,700,500,'no','no','no','no','objPopup');
				return false;
		}
	});
}

function initPage()
{
	if (!(("standalone" in window.navigator) && window.navigator.standalone)) {
		bindPageEventHandlers();
	}
    if (isLoggedInPage()) {
        updatePageTimestamp();
        var TIMEOUTCHECK_INTERVAL = 62000;
        mobjTimeoutInterval = setInterval(checkForTimeout, TIMEOUTCHECK_INTERVAL);
        console.log("timeout setup");
    }
    $("#openapplictioncont").load(__site + '/dynamic/status/openapplication');
    mobjInitPoInterval = setTimeout(function(){$("#unreadmail").load(__site + '/dynamic/status/getunreadmail')},500);
    console.log("finished init");
}

function openPopup(strURL,intWidth,intHeight,strLocation,strMenubar,strTitlebar,strToolbar,strWindowName)
{
	if(!strLocation)
		strLocation="no";
	if(!strMenubar)
		strMenubar="no";
	if(!strTitlebar)
		strTitlebar="no";
	if(!strToolbar)
		strToolbar="no";
	if(!intWidth)
		intWidth=window.innerWidth*0.9;
	if(!intHeight)
		intHeight=window.innerHeight*0.9;
	if(!strWindowName)
		strWindowName='objPopup';
	objPopup=window.open(strURL,strWindowName,'width='+intWidth+',height='+intHeight+',top=50,left=50,resizable=yes,scrollbars=yes,location='+strLocation+',menubar='+strMenubar+',titlebar='+strTitlebar+',toolbar='+strToolbar);
	if(objPopup)
		objPopup.focus();
	return objPopup;
}

function checkForTimeout() {
    if (isSessionExpired()) {
        window.onbeforeunload = null;
        clearTimeoutCheck();
        window.location.href = "/logout";   
    }
    console.log("ckSTS checked");
    $("#openapplictioncont").load(__site + '/dynamic/status/openapplication');
    $("#unreadmail").load(__site + '/dynamic/status/getunreadmail');
}

function clearTimeoutCheck() {
    if (mobjTimeoutInterval)
        clearInterval(mobjTimeoutInterval);
}

function isLoggedInPage() {
    if (mblLoggedInPage == null) {
        mblLoggedInPage = ($('.glyphicons-user').length > 0);
    }
    return mblLoggedInPage;
}

function isSessionExpired() {
    if (!isLoggedInPage())
        return false;
    var SESSION_DURATION = 31;
    var intSessionTimestamp = getCookie('ckSTS');
    if (isNaN(intSessionTimestamp))
        return false;
    else
        intSessionTimestamp = parseInt(intSessionTimestamp);
    var currentTime = new Date();
    var intSessionAgeMinutes = ((currentTime.getTime() - intSessionTimestamp) / 60000);
    return intSessionAgeMinutes > SESSION_DURATION;
}

function updatePageTimestamp() {
    var currentTime = new Date();
    setCookie('ckSTS', currentTime.getTime());
    console.log("ckSTS updated");
}

function utf8Decode(utftext) {
    var string = "";
    var i = 0;
    var c = c1 = c2 = 0;
    while (i < utftext.length) {
        c = utftext.charCodeAt(i);
        if (c < 128) {
            string += String.fromCharCode(c);
            i++;
        } else if ((c > 191) && (c < 224)) {
            c2 = utftext.charCodeAt(i + 1);
            string += String.fromCharCode(((c & 31) << 6) | (c2 & 63));
            i += 2;
        } else {
            c2 = utftext.charCodeAt(i + 1);
            c3 = utftext.charCodeAt(i + 2);
            string += String.fromCharCode(((c & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63));
            i += 3;
        }
    }
    return string;
}

function utf8Encode(string) {
    string = string.replace(/\r\n/g, "\n");
    var utftext = "";
    for (var n = 0; n < string.length; n++) {
        var c = string.charCodeAt(n);
        if (c < 128) {
            utftext += String.fromCharCode(c);
        } else if ((c > 127) && (c < 2048)) {
            utftext += String.fromCharCode((c >> 6) | 192);
            utftext += String.fromCharCode((c & 63) | 128);
        } else {
            utftext += String.fromCharCode((c >> 12) | 224);
            utftext += String.fromCharCode(((c >> 6) & 63) | 128);
            utftext += String.fromCharCode((c & 63) | 128);
        }
    }
    return utftext;
}

$(document).ajaxComplete(function(e, xhr, options) {
    if (options.url != 'dynamic/status/openapplication' && options.url != 'dynamic/status/getunreadmail') {
        updatePageTimestamp();
    }
});

(function($) {
     
    $.fn.dataTable.moment = function ( format, locale ) {
        var types = $.fn.dataTable.ext.type;
     
        // Add type detection
        types.detect.unshift( function ( d ) {
            // Null and empty values are acceptable
            if ( d === '' || d === null ) {
                return 'moment-'+format;
            }
     
            return moment( d.substring(0, format.length).replace ? d.substring(0, format.length).replace(/<.*?>/g, '') : d.substring(0, format.length), format, locale, true ).isValid() ?
                'moment-'+format :
                null;
        } );
     
        // Add sorting method - use an integer for the sorting
        types.order[ 'moment-'+format+'-pre' ] = function ( d ) {
            return d === '' || d === null ?
                -Infinity :
                parseInt( moment( d.substring(0, format.length).replace ? d.substring(0, format.length).replace(/<.*?>/g, '') : d.substring(0, format.length), format, locale, true ).format( 'x' ), 10 );
        };
    };
     
}(jQuery));
